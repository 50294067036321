import React, { useEffect, useState } from 'react'
import { OTPInput } from 'input-otp'
import PropTypes from 'prop-types'

import { 
  StyledOTPSlot, 
  StyledOTPWrapper, 
  StyledCaretContainer,
  StyledCaret, 
} from './styles'
import { FieldLabel } from '../../atoms/fieldLabel'

const FieldVerificationCode = (props) => {
  const {
    autoFocus,
    length = 6,
    disabled,
    placeholder,
    label,
    onChange,
  } = props

  const [value, setValue] = useState('');

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    if ('OTPCredential' in window) {
      const ac = new AbortController();
      navigator.credentials.get({
        otp: { transport: ['sms'] },
        signal: ac.signal,
      }).then(otp => {
        handleChange(otp?.code);
      }).catch(() => {
        ac.abort();
      });

      return () => {
        ac.abort();
      };
    }
  }, []);

  return (
    <div>
      {label && <FieldLabel>{label}</FieldLabel>}

      <OTPInput 
        value={value}
        maxLength={length} 
        disabled={disabled}
        autoFocus={autoFocus}
        onChange={handleChange}
        placeholder={placeholder}
        render={({ slots }) => (
          <StyledOTPWrapper>
            {slots.map((slot, index) => (
              <StyledOTPSlot key={index}>
                {slot.char ?? slot.placeholderChar}
                {slot.hasFakeCaret && <FakeCaret />}
              </StyledOTPSlot>
            ))}
          </StyledOTPWrapper>
        )}
      />
    </div>
  )
}

function FakeCaret() {
  return (
    <StyledCaretContainer>
      <StyledCaret />
    </StyledCaretContainer>
  )
}

FieldVerificationCode.defaultProps = {
  allowedCharacters: 'numeric',
  autoFocus: true,
  length: 6,
  disabled: false,
  placeholder: '',
  containerClass: '',
  label: null,
  onChange: () => {},
}

FieldVerificationCode.propTypes = {
  allowedCharacters: PropTypes.oneOf(['alpha', 'alphanumeric', 'numeric']),
  autoFocus: PropTypes.bool,
  length: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  containerClass: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
}

export { FieldVerificationCode }