import styled, { keyframes } from 'styled-components'
import { themeMedia, themeStyles } from '../../../styles/theme'
import { brandConfig } from '../../../config/brandConfig'

export const inputBorderSize = '2px'

export const StyledOTPWrapper = styled.div`
  display: flex;
  gap: 10px;

  ${themeMedia.maxMobile`
    gap: 6px;
  `}
`

export const StyledOTPSlot = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  font-size: ${brandConfig.fonts.text.input.size.desktop};
  font-family: ${brandConfig.fonts.text.input.family};
  color: ${themeStyles.colors.black.main};
  border: ${inputBorderSize} solid ${themeStyles.colors.lightGreys[1].main};
  border-radius: 6px;
  outline: none !important;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  ${themeMedia.maxMobile`
    width: 12.8vw;
    height: 12.8vw;
  `}
`

const caretBlink = keyframes`
  0%, 70%, 100% { opacity: 1; }
  20%, 50% { opacity: 0; }
`

export const StyledCaretContainer = styled.div`
  position: absolute;
  pointer-events: none;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${caretBlink} 1s step-end infinite;
`

export const StyledCaret = styled.div`
  width: 1px;
  height: ${brandConfig.fonts.text.input.size.desktop};
  background-color: black;
`