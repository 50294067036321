import React, { useState } from 'react'
import { useTranslation } from '../../../context/translationProvider'
import useCookie from '../../../hooks/useCookie'
import { toast } from '../../atoms'
import { Checkbox } from '../../atoms/checkbox'
import { Button } from '../../atoms/button'
import PropTypes from 'prop-types'
import {
  CookieSettingsButtonWrapper,
  CookieSettingsCheckboxWrapper,
  CookieSettingsH1,
  CookieSettingsP,
  CookieSettingsWrapper,
} from './styles'
import { useEventTracking } from '../../../context/eventTrackingProvider'

const CookieSettings = (props) => {
  const { translate } = useTranslation()
  const [cookie, updateCookie] = useCookie('caccept', '')
  const [paCookie, updatePaCookie] = useCookie('paccept', '')
  const { optUserIn, optUserOut } = useEventTracking()

  // Force check if user opens cookie settings modal from the main banner.
  const [adStorageCookieChecked, setAdStorageCookieChecked] = useState(
    props.forceCheck ? true : cookie === 'on'
  )

  const [analiticsStorageCookieChecked, setAnaliticsStorageCookieChecked] =
    useState(props.forceCheck ? true : paCookie === 'on')

  const onAdStorageChange = () => {
    setAdStorageCookieChecked((c) => !c)
  }

  const onAnaliticyStorageInputChange = () => {
    setAnaliticsStorageCookieChecked((c) => !c)
  }

  const onSubmit = () => {
    if (props.onClose) {
      props.onClose()
    }
    updateCookie(adStorageCookieChecked ? 'on' : 'off', 365)
    updatePaCookie(analiticsStorageCookieChecked ? 'on' : 'off', 365)
    analiticsStorageCookieChecked ? optUserIn() : optUserOut()

    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
      window.gtag('consent', 'update', {
        ad_storage: adStorageCookieChecked ? 'granted' : 'denied',
        analytics_storage: analiticsStorageCookieChecked ? 'granted' : 'denied',
      })
    }

    if (props.onUpdate) {
      props.onUpdate()
    }
    toast.success(translate('common.cookie.saveSettings'))
  }

  return (
    <CookieSettingsWrapper>
      <CookieSettingsH1>
        <span>{translate('common.cookie.necessaryCookies')}</span>
      </CookieSettingsH1>
      <CookieSettingsP
        dangerouslySetInnerHTML={{
          __html: translate('common.cookie.necessaryCookiesDisclaimer'),
        }}
      />

      <CookieSettingsCheckboxWrapper disabled>
        <Checkbox
          formProperties={{
            name: 'necessaryCookies',
          }}
          checked
          disabled
        >
          <div
            dangerouslySetInnerHTML={{
              __html: translate('common.cookie.necessaryCookies'),
            }}
          />
        </Checkbox>
      </CookieSettingsCheckboxWrapper>

      <CookieSettingsH1>
        <span>{translate('common.cookie.advertCookies')}</span>
      </CookieSettingsH1>
      <CookieSettingsP>
        <span>{translate('common.cookie.advertCookiesDisclaimer')}</span>
      </CookieSettingsP>

      <CookieSettingsCheckboxWrapper onClick={onAdStorageChange}>
        <Checkbox
          formProperties={{
            name: 'adStorageCookie',
          }}
          checked={adStorageCookieChecked}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: translate('common.cookie.advertCookies'),
            }}
          />
        </Checkbox>
      </CookieSettingsCheckboxWrapper>

      <CookieSettingsH1>
        <span>{translate('common.cookie.performanceAnalyticsCookies')}</span>
      </CookieSettingsH1>
      <CookieSettingsP>
        <span>
          {translate('common.cookie.performanceAnalyticsCookiesDisclaimer')}
        </span>
      </CookieSettingsP>

      <CookieSettingsCheckboxWrapper onClick={onAnaliticyStorageInputChange}>
        <Checkbox
          formProperties={{
            name: 'analiticsStorageCookie',
          }}
          checked={analiticsStorageCookieChecked}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: translate('common.cookie.performanceAnalyticsCookies'),
            }}
          />
        </Checkbox>
      </CookieSettingsCheckboxWrapper>

      <CookieSettingsButtonWrapper>
        <Button onClick={onSubmit} primary>
          {translate('common.saveSettings')}
        </Button>
      </CookieSettingsButtonWrapper>
    </CookieSettingsWrapper>
  )
}

CookieSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  forceCheck: PropTypes.bool,
}

export default CookieSettings
